<template>
  <div class="login-account">
    <h2>欢迎登录工作台</h2>
    <input-box-vue label="账号" v-model="personal.uerName" placeholder="请输入账号" class="mb40"/>
    <password-vue label="密码" forgetPass="1" v-model="personal.pass" placeholder="请输入密码"/>
    <button class="next-btn" v-if="isBtn" @click="login" style="margin-top: 65px;">登录</button>
    <button class="not-next-btn" v-else style="margin-top: 65px;">登录</button>
  </div>
</template>

<script>
import inputBoxVue from "@/components/inputBox";
import passwordVue from "@/components/password";
export default {
  name: "loginAccount",
  components: { inputBoxVue, passwordVue },
  data() {
    return {
      isBtn: false,
      personal: {
        TYPE: "2", // 密码登录
        uerName: "",
        pass: "",
        entList: []
      }
    };
  },
  watch: {
    personal: {
      handler(newVal) {
        if (newVal.uerName != "" && newVal.pass && newVal.pass.length >= 8) {
          this.isBtn = true;
        } else {
          this.isBtn = false;
        }
      },
      deep: true
    }
  },
  created() {
    //  let that = this;
    //     document.onkeypress = function(e) {
    //     console.log('ele:',e)
    //     var keycode = e.keyCode
    //     if (keycode == 13) {
    //         that.login();// 登录方法名
    //         return false;
    //     }
    // };
    // this.$nextTick(() => {
    //     var that = this;
    //     document.querySelector('.login').onkeydown = function (e) {
    //         var key = window.event.keyCode;
    //         if (key == 13) {
    //             that.login()
    //         }
    //     }
    // })
  },
  methods: {
    login() {
      this.axios("POST", `${this.$phpBaseURL}v1/login`, {
        name: this.personal.uerName,
        pass: this.personal.pass
      }).then(res => {
        if (res.code == 1) {
          this.personal = res.data;
          //                        this.$emit('login', this.personal);
          let str = JSON.stringify(res.data);

          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user_info", str);

          //根据权限跳转
          this.$router.push({
            path: res.data.menu_to,
            query: { form: "login" }
          });
          //                        this.$router.push({path: '/customer',query:{form:'login'}});
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.login-account {
  h2 {
    font-size: 28px;
    font-weight: 500;
    color: rgba(25, 39, 68, 1);
    margin-bottom: 24px;
  }
}
</style>