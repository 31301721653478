<template>
    <div class="login-tel">
        <div class="register-one" v-show="sep==0">
            <h3 class="title">登录到营销人</h3>
            <input-box-vue label="" placeholder="请输入您的手机号" v-model="personal.mobile" inputType="tel"/>
            <button class="next-btn" type="button" @click="nextFn(1)" style="margin-top: 88px;" v-if="isBtn.isTelBtn">下一步</button>
            <button class="not-next-btn" type="button" style="margin-top: 88px;" v-else>下一步</button>
        </div>
        <div class="register-tow" v-show="sep==1">
            <div class="previous" @click="nextFn(0)">&lt;返回重新输入手机号</div>
            <h3 class="title">验证手机号</h3>
            <p class="code-tishi" v-if="isBtn.isCodeTs">请输入发送至<span>{{personal.tel}}</span>的验证码，有效期十分钟。如未收到，请尝试重新获取验证码。</p>
            <p class="code-cuowu" v-else>验证码错误，请重新输入。</p>
            <code-vue v-model="personal.code"/>
            <span class="getCode1" v-if="isBtn.isCodeTs">{{second}}秒之后可重新获取验证码</span>
            <span class="getCode2" v-else @click="getCode">重新获取验证码</span>
            <button class="next-btn" type="button" @click="nextFn(2)" style="margin-top: 88px;" v-if="isBtn.isCodeBtn">下一步</button>
            <button class="not-next-btn" type="button" style="margin-top: 88px;" v-else>下一步</button>
        </div>
    </div>
</template>

<script>
    import inputBoxVue from '@/components/inputBox'
    import codeVue from '@/components/code'
    import EntExplainVue from "./EntExplain";
    export default {
        name: "loginTel",
        components:{
            codeVue,     // 验证码组件
            inputBoxVue, // input组件
        },
        data(){
            return {
                'sep': 0,                // 第几步
                'second': 60,            // 获取验证码秒数
                'personal':{
                    'TYPE':'1',    // 手机号登录
                    'explainCheck':false,// 说明勾选
                    'mobile':'',            // 手机号
                    'code':'',           // 验证码
                    'entList':[],
                },
                'isBtn':{
                    'isTelBtn':false, // 手机号下一步按钮
                    'isCodeBtn':false,
                    'isCodeTs':true,
                },
            }
        },
        watch:{
            'personal':{
                handler(newVal,val){
                    if(newVal.mobile.length == 11){
                        this.isBtn.isTelBtn = true;
                    }else{
                        this.isBtn.isTelBtn = false;
                    }
                    if(newVal.code.length  == 6){
                        this.isBtn.isCodeBtn = true;
                    }else{
                        this.isBtn.isCodeBtn = false;
                    }
                },
                deep:true,
            }
        },
        methods: {
            // 下一步
            nextFn(index) {
                // 完成手机填写下一步
                if (index == 1) {
                    if (this.personal.mobile == '') { return this.$message({message: '请输入手机号', type: 'warning'}); }
                    if (!this.$cm.checkPhone(this.personal.mobile)) { return this.$message({message: '请输入正确手机号', type: 'warning'}); }
                    // if (!this.personal.explainCheck) { return this.$message({message: '请勾选《服务协议》和《隐私政策》', type: 'warning'}); }
                    this.getCode(index)
                    return false;
                }
                // 完成验证码下一步
                if (index == 2) {
                    if (this.personal.code == '') { return this.$message({message: '请输入验证码', type: 'warning'}); }
                    if (this.personal.code.length != 6) { return this.$message({message: '请输入正确的验证码', type: 'warning'}); }
                    this.login();
                    return false;
                }
            },
            getCode(index){
                this.axios('post',`${this.$phpBaseURL}auth/v1/login/captcha`,{
                    mobile:this.personal.mobile,
                }).then(res=>{
                    if(res.code == 1){
                        this.$message({ message: '获取验证码成功', type: 'success' });
                        if(index){
                            this.sep = index;
                        }
                    }else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            },
            login(){
                this.axios('put',`${this.$phpBaseURL}auth/v1/mobileLogin`,{
                    mobile:this.personal.mobile,
                    captcha:this.personal.code,
                }).then(res=>{
                    if(res.code == 1){
                        this.personal.entList = res.data;
                        this.$emit('login', this.personal)
                    }else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    .login-tel{
        .title{
            font-size:28px;
            font-weight:500;
            color:rgba(25,39,68,1);
            line-height:40px;
            margin-bottom: 32px;
        }
        .register-one{

            .explain-check{
                margin-top: 100px;
                label{
                    input{
                        margin-right: 8px;
                    }
                }
                span{
                    font-size:12px;
                    font-weight:400;
                    color:rgba(120,128,144,1);
                    line-height:17px;
                    i{
                        font-size:12px;
                        font-weight:400;
                        color:#4786FF;
                        line-height:17px;
                    }
                }
            }
        }
        .register-tow{
            padding-top: 115px;
            .previous{
                font-size:14px;
                font-weight:400;
                color:rgba(120,128,144,1);
                line-height:20px;
                cursor: pointer;
            }
            .code-tishi{
                font-size:16px;
                font-weight:400;
                color:rgba(120,128,144,1);
                line-height:22px;
                margin-bottom: 24px;
                span{
                    color: #192744;
                    font-size: 20px;
                }
            }
            .code-cuowu{
                font-size:16px;
                font-weight:400;
                color:rgba(254,102,100,1);
                line-height:22px;
                margin-bottom: 54px;
            }
            .getCode1{
                font-size:18px;
                font-weight:400;
                color:rgba(120,128,144,1);
                line-height:25px;
            }
            .getCode2{
                font-size:16px;
                font-weight:400;
                color:rgba(71,134,255,1);
                line-height:22px;
            }
        }
    }

</style>