<template>
  <div class="login">
    <div class="loginL">
      <ent-explain-vue/>
    </div>
    <div class="loginR">
      <div class="login-form">
        <div class="head"></div>
        <login-account-vue v-if="sep" @login="login"/>
        <login-tel-vue v-else @login="login"/>
      </div>
    </div>
    <div class="model" v-if="isEnt">
      <div class="model-box"></div>
      <div class="model-center">
        <div class="model-head">
          <span>选择已有企业</span>
        </div>
        <div class="model-body entList">
          <dl
            class="item"
            v-for="(e,i) in personal.entList"
            :key="i"
            :class="{'active':e.admin_uuid == admin_uuid}"
            @click="entClick(e)"
          >
            <dt>
              <img :src="e.logo" alt v-if="e.logo">
              <span v-else>{{e.company_name[0]}}</span>
            </dt>
            <dd>
              {{e.company_name}}
              <i class="yw yxrwzuijiaqudao" v-if="e.is_auth == 1"></i>
            </dd>
          </dl>
        </div>
        <div class="model-foot">
          <button class="successBtn" @click="success">登录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntExplainVue from "./com/EntExplain.vue";
import loginTelVue from "./com/loginTel.vue";
import loginAccountVue from "./com/loginAccount.vue";
export default {
  name: "login",
  components: { EntExplainVue, loginTelVue, loginAccountVue },
  created() {
    if (location.href.includes("?auth_code=")) {
      this.qyWechatScanLogin();
    }
    if (localStorage.getItem("token")) {
      this.$router.push("/customer");
    }
  },
  data() {
    return {
      sep: true,
      isEnt: false,
      admin_uuid: "",
      personal: null,
      obj: null
    };
  },
  mounted() {
    this.obj = this.common.getRequest();
  },
  methods: {
    qyWechatScanLogin() {
      let _this = this;
      this.axios("PUT", this.common.php + "auth/v1/qyWechatScanLogin", {
        auth_code: this.common.getRequest().auth_code
      }).then(res => {
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          _this.common.getSignatureIds(
            location.origin + "/web/" + _this.$route.path
          );
          _this.$router.push("/customer");
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        }
      });
    },
    entWxLoginFn() {
      location.href = `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=wxfbca7d8fb03e4ac6&redirect_uri=${process.env.VUE_APP_API_URL}web/login&usertype=member`;
    },
    switchLoginFn() {
      this.sep = !this.sep;
    },
    login(data) {
      this.personal = data;
      if (data.entList.length == 1) {
        this.admin_uuid = data.entList[0].admin_uuid;
        this.success();
        return false;
      }
      this.isEnt = true;
    },
    entClick(item) {
      this.admin_uuid = item.admin_uuid;
    },
    success() {
      if (this.admin_uuid == "") {
        return this.$message.error("请先选择一个企业登录!");
      }
      var p = {};
      if (this.personal.TYPE == 1) {
        p = {
          mobile: this.personal.mobile,
          captcha: this.personal.code,
          admin_uuid: this.admin_uuid
        };
        this.telLogin(p);
      } else {
        p = {
          mobile: this.personal.uerName,
          password: this.personal.pass,
          admin_uuid: this.admin_uuid
        };
        this.accountLogin(p);
      }
    },
    accountLogin(p) {
      this.axios("PUT", `${this.$phpBaseURL}auth/v1/login`, p).then(res => {
        if (res.code == 1) {
          this.$message.success("登录成功");
          localStorage.setItem("token", res.data.token);
          if (this.obj.redirect_uri) {
            location.href = `${this.obj.redirect_uri}?token=${res.data.token}`;
          } else {
            this.$router.push("/customer");
          }
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    telLogin(p) {
      this.axios("put", `${this.$phpBaseURL}auth/v1/mobileLogin`, p).then(
        res => {
          if (res.code == 1) {
            this.$message.success("登录成功");
            localStorage.setItem("token", res.data.token);
            if (this.obj.redirect_uri) {
              location.href = `${this.obj.redirect_uri}?token=${res.data.token}`;
            } else {
              this.$router.push("/customer");
            }
          } else if (res.code == 2) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    clear() {
      this.isEnt = false;
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100%;
  display: flex;
  .loginL,
  .loginR {
    width: 50%;
    box-sizing: border-box;
  }
  .entList {
    display: flex;
    justify-content: center;
    .item {
      margin-bottom: 16px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      dt {
        width: 200px;
        height: 40px;
        overflow: hidden;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
        span {
          font-size: 20px;
          font-weight: 500;
        }
      }
      dd {
        padding: 8px 0;
        text-align: center;
        i {
          margin-left: 8px;
        }
      }
      &.active:after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 0;
        left: 0;
        border: 10px solid #ff6e5a;
        border-bottom-color: transparent;
        border-right-color: transparent;
      }
    }
  }
  /*.loginL{background: #f5f5f5}*/
  .loginR {
    padding: 0 150px;
    .login-form {
      .head {
        padding-top: 50px;
        padding-bottom: 98px;
        text-align: right;
        font-size: 14px;
        span {
          color: #788090;
        }
        b {
          margin-left: 10px;
          color: #4786ff;
          cursor: pointer;
        }
      }
      .other-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 48px;
        .entWxLoginBtn {
          color: #788090;
          font-size: 14px;
          cursor: pointer;
          vertical-align: middle;
          display: flex;
          align-items: center;
          img {
            width: 28px;
            margin-right: 4px;
          }
        }
        .telLoginBtn {
          color: #788090;
          font-size: 14px;
          cursor: pointer;
          vertical-align: middle;
          display: flex;
          align-items: center;
          img {
            width: 13px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .login {
    flex-flow: column;
    .loginL {
      display: none;
    }
    .loginR {
      width: 100%;
      padding: 0 20px;
    }
  }
}
</style>